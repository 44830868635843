// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-activities-js": () => import("./../../../src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-contact-and-collaboration-js": () => import("./../../../src/pages/contact_and_collaboration.js" /* webpackChunkName: "component---src-pages-contact-and-collaboration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publications-and-refs-js": () => import("./../../../src/pages/publications_and_refs.js" /* webpackChunkName: "component---src-pages-publications-and-refs-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-activity-js": () => import("./../../../src/templates/activity.js" /* webpackChunkName: "component---src-templates-activity-js" */),
  "component---src-templates-background-js": () => import("./../../../src/templates/background.js" /* webpackChunkName: "component---src-templates-background-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/publication.js" /* webpackChunkName: "component---src-templates-publication-js" */)
}

